import { LANGUAGE } from "../utils/languageUtils";
import { removePrefix } from "../utils/utils";

export const BASE_URL =
  "https://public-api.wordpress.com/wp/v2/sites/xoicomadmin.wordpress.com/posts";
export const TAG_URL =
  "https://public-api.wordpress.com/wp/v2/sites/xoicomadmin.wordpress.com/tags?per_page=50";
export const CATEGORY_URL =
  "https://public-api.wordpress.com/wp/v2/sites/xoicomadmin.wordpress.com/categories?per_page=50";
export const VIEWER_PASSWORD = "xoicom123";
export const INGREDIENT_PARALLAX = 770969147;
export const HISTORY = 770969122;
export const SPACE_TEXT = 770969286;
export const SPACE_SLIDE = 770969262;
export const SPACE_PARALLAX = 770969239;
export const ABOUT_NUMBER = 770969131;
export const BLOG = 273;
export const INGREDIENT = 32568;
export const PRESS_REVIEW = 770969215;
export const CONTACT_BOOKING = 770969135;
export const CONTACT_POLICY = 23637649;

// banner category
export const HOME_BACKGROUND = 770969166;
export const ABOUT_BACKGROUND = 775007502;
export const INGREDIENT_BACKGROUND = 775007521;
export const SPACE_BACKGROUND = 775007531;

// blog category
export const LOCAL_FOOD_BLOG = 24163206;
export const NEWS_BLOG = 90720;
export const COOKING_TIPS_BLOG = 57154304;

//api call
export const fetchByCategory = async (category, extraQueryString) => {
  const response = await fetch(
    `${BASE_URL}/?categories=${category}&password=${VIEWER_PASSWORD}${
      extraQueryString ? `&${extraQueryString}` : ""
    }`
  );
  const data = await response.json();
  return data;
};

export const fetchByCategoryAndId = async (category, id) => {
  const response = await fetch(
    `${BASE_URL}/${id}?categories=${category}&password=${VIEWER_PASSWORD}`
  );
  const data = await response.json();
  return data;
};

export const fetchTag = async (extraQueryString) => {
  const response = await fetch(
    `${TAG_URL}${extraQueryString ? `&${extraQueryString}` : ""}`
  );
  const data = await response.json();

  return data;
};

export const fetchCategories = async (extraQueryString) => {
  const response = await fetch(
    `${CATEGORY_URL}${extraQueryString ? `&${extraQueryString}` : ""}`
  );
  const data = await response.json();

  return data;
};

//methods
//multilanguage
export const getPostsByLanguage = (posts, language) => {
  let multiLanguagePost;
  const availableForeignLanguageIds = Object.values(LANGUAGE)
    .filter((language) => language.value !== "vn")
    .map((language) => language.id);

  if (language && language !== "vn") {
    multiLanguagePost = posts.filter((data) =>
      data.tags.includes(LANGUAGE[language].id)
    );
  } else {
    // if no language is specified, get the tag with vn tag or no tag
    multiLanguagePost = posts.filter((data) =>
      data.tags.every((tag) => !availableForeignLanguageIds.includes(tag))
    );
  }

  return multiLanguagePost;
};

export const getPostByLanguageTitle = (posts, language, title) => {
  const multiLanguagePost = posts.find(
    (post) =>
      post.tags.includes(LANGUAGE[language].id) && post.slug.startsWith(title)
  );

  return multiLanguagePost;
};

export const getMultiLanguagePost = (data) => {
  const languages = Object.keys(LANGUAGE);
  let posts = {};
  languages.forEach((language) => {
    posts[language] = getPostsByLanguage(data, language)[0];
  });
  return posts;
};

export const getMultiLanguagePostMapping = (post, data, title) => {
  let posts = {};
  const languages = Object.keys(LANGUAGE).filter(
    (language) => language !== "vn"
  );

  languages.forEach((language) => {
    posts[language] = getPostByLanguageTitle(data, language, title) || post;
  });
  return posts;
};

// ingredient api
export const getIngredientCategoryNames = (categories, endPhrase) => {
  const tagNames = categories
    .filter((tag) => tag.count !== 0 && tag.slug.endsWith(endPhrase))
    .map((tag) => tag.slug.replace(`-${endPhrase}`, ""));
  return tagNames;
};

//blog api
export const getTagsFromBlog = (allTags, existingTags, startString) => {
  const allMatchingTags = allTags.filter(
    ({ count, name }) => count && name.startsWith(startString)
  );
  const matchingTags = allMatchingTags
    .filter((tag) => existingTags.includes(tag.id))
    .map((tag) => removePrefix(tag.name, startString));

  return matchingTags;
};
