import React, { useEffect, useRef, useState } from "react";
import "../style/Space.css";
import { SPACE_IMAGES } from "../utils/spaceUtils";
import { setNavBarMode } from "../api/xoicom.reducer";
import { useDispatch, useSelector } from "react-redux";
import { hasData, scrollToTop } from "../utils/utils";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import { motion, useScroll, useTransform } from "framer-motion";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import AddressSession from "../session/AddressSession";
import {
  fetchSpaceImages,
  fetchSpaceText,
} from "../api/space/space.reduxThunk";
import SlideWrapper from "../components/generics/SlideWrapper";
import SwipeWrapper from "../components/generics/SwipeWrapper";
import { slideUp } from "../utils/animationUtils";
import XoiComHeading from "../components/generics/XoiComHeading";
import NepSongSession from "../components/space/NepSongSession";
import OutsideSession from "../components/space/OutsideSession";
import NatureSession from "../components/space/NatureSession";
import PieceSession from "../components/space/PieceSession";
import LazyImage from "../components/generics/LazyImage";
import { fetchSpaceBG } from "../api/reduxThunk";

const { TREO_QUAN_AO } = SPACE_IMAGES;

function Space() {
  const dispatch = useDispatch();
  const spaceBackground = useSelector((state) => state.reducer.spaceBackground);
  const spaceText = useSelector((state) => state.reducer.spaceText);
  const spaceImages = useSelector((state) => state.reducer.spaceImages);
  const sliderImages = spaceImages.slider;
  const parallaxImages = spaceImages.parallax;
  const { community, outside, nepSong, nature, piece } = spaceText;

  const language = useSelector((state) => state.reducer.language);
  const loading = useSelector((state) => state.reducer.loading);

  useEffect(() => {
    scrollToTop();
    dispatch(setNavBarMode("light"));
    dispatch(fetchSpaceImages());
    dispatch(fetchSpaceBG());
  }, []);

  useEffect(() => {
    dispatch(fetchSpaceText());
  }, [language]);

  const mobile = useMediaQuery(breakpoint.mobile);
  const [openSlide, setOpenSlide] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleOpenSlide = (index) => {
    setSelectedImage(index);
    setOpenSlide(true);
  };
  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  const targetRef = useRef();
  const backgroundRef = useRef();
  const backgroundRef2 = useRef();

  const { scrollYProgress: scrollYProgressBackground } = useScroll({
    target: backgroundRef,
    offset: ["center end", "start start"],
  });

  const { scrollYProgress: scrollYProgressBackground2 } = useScroll({
    target: backgroundRef2,
    offset: ["start start", "0.2 start"],
  });

  const backgroundColor = useTransform(
    scrollYProgressBackground,
    [0, 1],
    ["#FFFFFF", "#001624"]
  );
  const backgroundColor2 = useTransform(
    scrollYProgressBackground2,
    [0, 1],
    ["#001624", "#fffaf3"]
  );

  return (
    <div style={{ overflowX: "hidden" }}>
      <LoadingBackdrop open={loading} />
      <LazyImage
        className="landing-session"
        alt="space-landing"
        imageSrc={spaceBackground}
      />
      <motion.div style={{ backgroundColor: backgroundColor }}>
        <div className="space-community-session">
          <div className="space-community-text-container">
            <div className="space-community-heading">
              <XoiComHeading heading={community.heading} />
            </div>
            <div className="body-b1">{community.bodyText}</div>
          </div>
          {mobile && (
            <div className="space-outside-mobile">
              <OutsideSession
                images={parallaxImages}
                content={outside}
                openSlide={openSlide}
                handleOpenSlide={handleOpenSlide}
                handleCloseSlide={handleCloseSlide}
                selectedImage={selectedImage}
              />
            </div>
          )}
          <div className="khu-tap-the-graphic">
            <div className="stair"></div>
            <img
              className="treo-quan-ao"
              src={TREO_QUAN_AO}
              alt="treo-quan-ao"
            />
            {!mobile && (
              <motion.div
                className="space-outside"
                variants={slideUp}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
              >
                <OutsideSession
                  images={parallaxImages}
                  content={outside}
                  openSlide={openSlide}
                  handleOpenSlide={handleOpenSlide}
                  handleCloseSlide={handleCloseSlide}
                  selectedImage={selectedImage}
                />
              </motion.div>
            )}
          </div>
          <NepSongSession
            content={nepSong}
            target={targetRef}
            images={parallaxImages}
            handleOpenSlide={handleOpenSlide}
          />
        </div>
        <NatureSession
          background={backgroundRef}
          content={nature}
          images={parallaxImages}
          handleOpenSlide={handleOpenSlide}
        />
      </motion.div>
      <motion.div
        ref={backgroundRef2}
        className="space-nature-piece-transition"
        style={{ backgroundColor: backgroundColor2 }}
      ></motion.div>
      <PieceSession backgroundColor={backgroundColor2} content={piece} />
      {hasData(sliderImages) && (
        <div>
          {mobile ? (
            <div className="image-slide-session-mobile">
              <SwipeWrapper steps={sliderImages} mode="image-slide" />
            </div>
          ) : (
            <div className="image-slide-session">
              <SlideWrapper steps={sliderImages} mode="image-slide" />
            </div>
          )}
        </div>
      )}
      <AddressSession mode="cta" />
    </div>
  );
}

export default Space;
