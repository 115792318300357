import React, { useEffect } from "react";
import "../style/Home.css";
import { useNavigate } from "react-router-dom";
import AddressSession from "../session/AddressSession";
import BlogList from "../components/home/BlogList";
import ReservationSession from "../session/ReservationSession";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarMode } from "../api/xoicom.reducer";
import { fetchHomeBG } from "../api/reduxThunk";
import IngredientParallax from "../components/home/IngredientParallax";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import {
  IMAGES,
  hasData,
  responsiveBlogPreview,
  saveToStorage,
  scrollToTop,
  toElement,
} from "../utils/utils";
import { motion } from "framer-motion";
import LazyImage from "../components/generics/LazyImage";
import { useMediaQuery } from "@mui/material";
import BlogListFull from "../components/blog/BlogListFull";
import { breakpoint } from "../utils/responsiveUtils";
import { fetchPressReviews } from "../api/review/review.reduxThunk";
import SlideWrapper from "../components/generics/SlideWrapper";
import SwipeWrapper from "../components/generics/SwipeWrapper";
import { slideUpDelay } from "../utils/animationUtils";
import XoiComHeading from "../components/generics/XoiComHeading";
import { fetchBlogs } from "../api/blog/blog.reduxThunk";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.reducer.loading);
  const homeBackground = useSelector((state) => state.reducer.homeBackground);
  const homeText = useSelector((state) => state.reducer.homeText);

  const food = useSelector((state) => state.reducer.foodImages);
  const review = useSelector((state) => state.reducer.pressReviews);
  const hasReview = hasData(review);

  const language = useSelector((state) => state.reducer.language);

  const mobile = useMediaQuery(breakpoint.mobile);
  const medium = useMediaQuery(breakpoint.medium);
  const large = useMediaQuery(breakpoint.large);

  const blogs = useSelector((state) => state.reducer.blogs);
  const responsiveBlogs =
    blogs && responsiveBlogPreview(blogs, { medium, large });
  const hasBlogs = hasData(blogs);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchHomeBG());
    dispatch(setNavBarMode("light"));
    console.log("Welcome to Xoi Com 2");
  }, []);

  useEffect(() => {
    dispatch(fetchPressReviews());
    dispatch(fetchBlogs());
  }, [language]);

  useEffect(() => {
    blogs && saveToStorage("BLOG", blogs);
  }, [blogs]);

  return (
    <div>
      <LoadingBackdrop open={loading} />
      <LazyImage
        className="landing-session"
        alt="home-landing"
        imageSrc={mobile ? homeBackground.mobile : homeBackground.desktop} //another image for mobile version
        mode={mobile && "mobile"}
        height={mobile && "auto"}
        width={mobile && "100%"}
      />
      <div className="about-letter-session">
        <div className="about-letter">
          <div className="about-letter-text-container">
            <h1>{homeText.letter.heading}</h1>
            <div className="body-b2" style={{ fontWeight: 300 }}>
              {toElement(homeText.letter.bodyText)}
            </div>
          </div>
        </div>
      </div>
      <div className="home-philosophy-session">
        <img
          className="hand-holding-chopstick-image"
          src={IMAGES.HAND_CHOPSTICK}
          alt="hand-holding-chopstick"
        />
        <div className="home-philosophy-text" style={{ color: "white" }}>
          <h1>{homeText.HOME_PHILOSOPHY.HEADING}</h1>
          <button
            className="custom-button-link"
            style={{ color: "white" }}
            onClick={() => {
              navigate("/about");
            }}
          >
            {homeText.HOME_PHILOSOPHY.BUTTON}
          </button>
        </div>
      </div>
      <div className="home-moi-com-session">
        <motion.div
          variants={slideUpDelay}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <div className="cap-r15">{homeText.HOME_MOI_COM_HEADING}</div>
        </motion.div>
        {food && (
          <div id="foodSlide" className="home-moi-com-slide">
            <SlideWrapper steps={food} mode="food" />
          </div>
        )}
      </div>
      <IngredientParallax text={homeText.INGREDIENT_PARALLAX} />
      {hasBlogs && (
        <div className="home-blog-list-container">
          <div className="com-ngon-heading">
            <XoiComHeading heading={homeText.HOME_BLOG_LIST_HEADING} />
          </div>
          {medium ? (
            <BlogList blogs={blogs} loading={loading} />
          ) : (
            <BlogListFull blogs={responsiveBlogs} loading={loading} />
          )}
        </div>
      )}
      {hasReview && (
        <motion.div className="home-press-review-session">
          <div className="cap-r15">{homeText.HOME_PRESS_REVIEW_HEADING}</div>
          {mobile ? (
            <SwipeWrapper steps={review} mode="review" />
          ) : (
            <SlideWrapper steps={review} mode="review" />
          )}
        </motion.div>
      )}
      <AddressSession mode="address" />
      <ReservationSession
        reservation={{
          heading: homeText.HOME_RESERVATION,
          bodyText: "Hotline: 0866810736",
          backgroundImage: "home",
        }}
      />
    </div>
  );
}

export default Home;
